import React, {useEffect} from "react";
import { Title, Section, Subtitle,  HighlightTextBold, LinkText, Text, NoteText } from "../../../App.styled";
import { ButtonContainer, LinkContainer, LinkBball} from './Basketball.styled'
import { Link } from 'react-router-dom';



function Basketball() {
   
    const mapsUrl = `https://maps.app.goo.gl/Cn2CmV5q6g4oaCMn8`;
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
      
        <Section>
           
            <Title>Basketball</Title>
            <Subtitle>Fall Season 24' begins August 31st</Subtitle>
            <ButtonContainer>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSejMeoPCFtdgDF7uhfzkcwCrKtA-7GYyhguJKH_23UsoKFFKg/viewform'}>
                                <HighlightTextBold>Player Registration</HighlightTextBold>
                </LinkBball>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSd6kRtUDUa17EO4kGzmvu7yzJzqeoruNIpuqPQIV8xathlkTg/viewform'}>
                                <HighlightTextBold>Team Registration</HighlightTextBold>
                </LinkBball>
            </ButtonContainer>

            <LinkContainer>
                {/* <LinkText href="/Basketball/Schedule">Fall 24' Schedule</LinkText> */}
                <LinkText href="/Basketball/PlayoffBracket">Fall 24' Playoffs</LinkText>
                <LinkText href={mapsUrl}>Directions to the EBC Training Center</LinkText>
            </LinkContainer>
          
            <NoteText> Ohana Sports is excited to announce a meaningful partnership with EBC, where EBC will serve as the host venue for our basketball league games. Aligned with Ohana Sports' values, EBC's slogan, "One Gym, One Family," perfectly encapsulates the spirit of unity and community that both organizations are committed to fostering. This collaboration promises to enhance the overall experience for our teams and participants, creating a dynamic and inclusive environment for everyone involved. Together, Ohana Sports and EBC look forward to cultivating a sense of family and camaraderie within the basketball community.</NoteText>
            
            <Text>
                We're committed to providing a comprehensive and engaging experience for all basketball fans.
                Keep this page bookmarked for the latest schedules, scores, and updates.
            </Text>

            <Text>
                For any inquiries or assistance, feel free to reach out to us. We're here to ensure you have the best experience with Ohana Sports.
            </Text>

            <Text>
                Mahalo for Supporting Ohana Sports!
            </Text>
        </Section>
       
    );
}

export default Basketball;