import styled from 'styled-components';
const goldenRatio = 1.61803398875;
export const Bracket = styled.div`
  margin: 1%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;

  @media screen and (max-width: 768px) {
   overflow: auto;
   justify-content:left;
  };
 
`;
export const Title = styled.h2`
color: #1f1f1f;
font-size: calc((${goldenRatio} * 20px)*${goldenRatio});
justify-content: center;
 
`;

export const Round = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 0; 

`;

export const RoundTitle = styled.div`
color: #1f1f1f;
font-size: calc((${goldenRatio} * 10px)*${goldenRatio});
text-align: center;
`;

export const Date = styled.div`
color: #34b3d1;
text-align: center;
font-weight: bold;
font-size: calc((${goldenRatio} * 5px)*${goldenRatio});
`;

export const GameFourTeams = styled.div`
display: flex;
flex-direction: column;
align-items: center;
border-radius: 4px;

${props => {
    let paddingTop,paddingBottom;

    switch (props.roundIndex) {
      case 1:
        paddingTop = '60px';
        paddingBottom = '50px';
        break;
      case 2:
        paddingTop = '150px';
        paddingBottom = '65px';
        break;
    default:
        paddingTop = '25px';
        paddingBottom = '20px';
    }
    return `
      padding-top: ${paddingTop};
      padding-bottom: ${paddingBottom};
    `;
  }};


`;

export const Game = styled.div`
display: flex;
flex-direction: column;
align-items: center;
border-radius: 4px;

${props => {
    let paddingTop,paddingBottom;

    switch (props.roundIndex) {
      case 1:
        paddingTop = '40px';
        paddingBottom = '50px';
        break;
      case 2:
        paddingTop = '95px';
        paddingBottom = '65px';
        break;
    case 3:
        paddingTop = '230px';
        paddingBottom = '10px';
        break;
    default:
        paddingTop = '120px';
        paddingBottom = '15px';
    }
    return `
      padding-top: ${paddingTop};
      padding-bottom: ${paddingBottom};
    `;
  }};


`;

export const HigherRankTeam = styled.div`
background: white;
position: relative;
line-height: 40px;
border-bottom: 1px solid #CCC;
width: 200px; 
height: 30px
`;

export const GameInfo = styled.div`
background: white;
position: relative;
display: flex;
align-items: center; 
justify-content: center;
width: 200px; 
font-weight: bold;
font-size: calc((${goldenRatio} * 5px)*${goldenRatio});
border-right: ${props => {
    switch (props.roundIndex) {
      case 2:
        return '0px solid #CCC'; 
      default:
        return '1px solid #CCC'; 
    }
  }};

height: ${props => {
  switch (props.roundIndex) {
    case 1:
      return '115px'; 
    case 2:  
    default:
      return '40px'; 
  }
}};
`;

export const LowerRankTeam = styled.div`
margin-bottom: 0;
width: 200px; 
height: 30px;
background: white;
position: relative;
line-height: 40px;

border-right: ${props => {
   
  switch (props.roundIndex) {
      
      case 2:
          return '0px solid #CCC'; 
      case 1:
      default:
          return '1px solid #CCC'; 
    }
   
}};

border-bottom: ${props => {
   
  switch (props.roundIndex) {
        case 2:
          return '0px solid #CCC'; 
        case 1:
      default:
          return '1px solid #CCC'; 
    }
   
}};





`;



