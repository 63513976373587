import React, { useState, useEffect } from 'react';

import {Title, Bracket, Round, GameFourTeams, LowerRankTeam, HigherRankTeam, GameInfo, RoundTitle, Date  } from './PlayoffBracket.styled'
import jsonData from '../../Basketball/fall24.json';
const PlayoffBracket  = () => {
  const [bracketData, setBracketData] = useState([]);

    useEffect(() => {
        setBracketData(jsonData[0].Playoffs);
    }, []);
  


    return ( <div>
    <Title>Fall 24' Playoffs</Title>
        <Bracket>
       
      {bracketData.map((round, roundIndex) => (
        <Round key={roundIndex} roundIndex={roundIndex}>
          <RoundTitle>{round.week}</RoundTitle>
          <Date>{round.date}</Date>
          {roundIndex !== 0}
          {round.games.map((game, gameIndex) => (
        <GameFourTeams key={gameIndex} roundIndex={roundIndex}>
          <HigherRankTeam style={{ fontWeight: game.score1 > game.score2 ? 'bold' : 'normal' }}>
            {game.team1}
          </HigherRankTeam>
          <GameInfo key={roundIndex} roundIndex={roundIndex}>{game.time}</GameInfo>
          <LowerRankTeam key={roundIndex} roundIndex={roundIndex} style={{ fontWeight: game.score2 > game.score1 ? 'bold' : 'normal' }}>
            {game.team2}
          </LowerRankTeam>
        </GameFourTeams>
          ))}
        </Round>
      ))}
    </Bracket>
    </div>
    
      );
    
};

export default PlayoffBracket;